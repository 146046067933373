<template>
    <el-container>
        <div class="center">
            网站正在改版升级中,将会尽快恢复服务,敬请期待!
        </div>
    </el-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'root',
  components: {}
}
</script>
<style scoped lang="scss">
    .el-container {
        background-color: #F5F6F7;
        position: relative;
        min-height: 100vh;
        position: relative;
        .center{
            color: #aaaaaa;
            font-size: 20px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
